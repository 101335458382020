<template>
  <a-table
    bordered
    :data-source="list"
    :pagination="{
      showSizeChanger: true,
      pageSizeOptions: ['10', '50', '100', '200'],
    }"
    rowKey="userId"
  >
    <a-table-column key="index" title="全院排名" align="center">
      <template slot-scope="text, row, index">{{ index + 1 }}</template>
    </a-table-column>

    <a-table-column key="name" title="姓名" data-index="name"></a-table-column>
    <a-table-column key="deptName" title="部门" data-index="deptName" align="center"></a-table-column>

    <a-table-column
      key="lateNum"
      title="迟到次数"
      align="center"
      :sorter="(x, y) => x.lateNum - y.lateNum"
    >
      <template slot-scope="text">
        <span style="color: #f00">{{ text.lateNum }}</span>
      </template>
    </a-table-column>
    <a-table-column
      key="lateLength"
      title="迟到累计时长(分钟)"
      align="center"
      :sorter="(x, y) => x.lateLength - y.lateLength"
    >
      <template slot-scope="text">
        <span style="color: #f00">{{ text.lateLength }}</span>
      </template>
    </a-table-column>

    <a-table-column
      key="earlyNum"
      title="早退次数"
      align="center"
      :sorter="(x, y) => x.earlyNum - y.earlyNum"
    >
      <template slot-scope="text">
        <span style="color: #f00">{{ text.earlyNum }}</span>
      </template>
    </a-table-column>
    <a-table-column
      key="earlyLength"
      title="早退累计时长(分钟)"
      align="center"
      :sorter="(x, y) => x.earlyLength - y.earlyLength"
    >
      <template slot-scope="text">
        <span style="color: #f00">{{ text.earlyLength }}</span>
      </template>
    </a-table-column>

    <a-table-column
      key="forgetNum"
      title="未打卡次数"
      align="center"
      :sorter="(x, y) => x.forgetNum - y.forgetNum"
    >
      <template slot-scope="text">
        <span style="color: #f00">{{ text.forgetNum }}</span>
      </template>
    </a-table-column>

    <a-table-column
      key="leaveNum"
      title="请假次数"
      align="center"
      :sorter="(x, y) => x.leaveNum - y.leaveNum"
    >
      <template slot-scope="text">
        <span style="color: #f00">{{ text.leaveNum }}</span>
      </template>
    </a-table-column>

    <a-table-column
      key="leaveLength"
      title="请假时长(天)"
      align="center"
      :sorter="(x, y) => x.leaveLength - y.leaveLength"
    >
      <template slot-scope="text">
        <span style="color: #f00">{{ text.leaveLength }}</span>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>