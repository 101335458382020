<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px">
          <a-month-picker :allowClear="false" style="width: 120px" v-model="currentMonth" />
        </a-space>
      </template>
    </Pane>

    <div class="container">
      <a-card class="card">
        <div class="header">
          <div class="title">全院数据</div>
        </div>

        <Analysis
          :list="[
            {
              title: '考勤人数',
              value: pureList.length,
            },
            {
              title: '迟到人数',
              value: abnormalNums.lateNum,
            },
            {
              title: '早退人数',
              value: abnormalNums.earlyNum,
            },
            {
              title: '未打卡人数',
              value: abnormalNums.forgetNum,
            },
            {
              title: '请假人数',
              value: holidayUserLength,
            },
            {
              title: '出差人数',
              value: businessUserLength,
            },
            {
              title: '公出人数',
              value: outUserLength,
            },
          ]"
        />
      </a-card>

      <Padding />

      <a-card class="card">
        <div class="header">
          <div class="title">各部门异常比例</div>
          <div class="extra">
            <a-space>
              <a-radio-group v-model="ratioType" button-style="solid">
                <a-radio-button value="lateNum">迟到</a-radio-button>
                <a-radio-button value="earlyNum">早退</a-radio-button>
                <a-radio-button value="forgetNum">未打卡</a-radio-button>
                <a-radio-button value="leaveNum">请假</a-radio-button>
              </a-radio-group>

              <a-button type="primary" @click="downloadDeptErrorRatio">导出</a-button>
            </a-space>
          </div>
        </div>

        <div class="print" id="dept-error-ratio">
          <AbnormalPieChart :list="depts" />
        </div>
      </a-card>

      <Padding />

      <a-card class="card">
        <div class="header">
          <div class="title">各部门异常情况</div>
          <div class="extra">
            <a href="#" @click.prevent="downloadDeptError">导出</a>
          </div>
        </div>

        <div class="print" id="dept-error">
          <AbnormalBarChart :list="abnormalNums.depts" />
        </div>
      </a-card>

      <Padding />

      <a-card class="card">
        <div class="header">
          <div class="title">异常排行榜</div>

          <div class="extra">
            <a-space>
              <a-select
                placeholder="部门"
                show-search
                style="width: 180px"
                :allowClear="true"
                :filter-option="$selectFilterOption"
                v-model="deptName"
              >
                <a-select-option v-for="item in deptList" :key="item" :value="item">{{ item }}</a-select-option>
              </a-select>

              <a-button type="primary" @click="downloadTable">导出</a-button>
            </a-space>
          </div>
        </div>

        <AbnormalTable :list="filteredList" />
      </a-card>

      <Padding />
    </div>
  </div>
</template>
  

<script>
import moment from "moment";
import {
  fetchAnalysis,
  fetchLeave,
  fetchCheckApply
} from "@/api/human-resources/clock";
import { getName, isLeapYear } from "@/utils/clock";
import { mapActions, mapState } from "vuex";

import Analysis from "./components/analysis.vue";
import AbnormalBarChart from "./components/abnormal-bar-chart.vue";
import AbnormalTable from "./components/abnormal-table.vue";
import AbnormalPieChart from "./components/abnormal-pie-chart.vue";

import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import { downloadByHtml } from "@/utils/xlsx";

export default {
  components: {
    Analysis,
    AbnormalBarChart,
    AbnormalTable,
    AbnormalPieChart
  },

  data() {
    return {
      currentMonth: moment(),

      columns: [],

      clocks: [],
      leaves: [],
      replaces: [],

      pureList: [],

      holidayUserLength: 0,
      businessUserLength: 0,
      outUserLength: 0,

      deptName: undefined,

      ratioType: "lateNum"
    };
  },

  computed: {
    ...mapState("holiday", ["holiday"]),

    deptList() {
      return this.pureList
        .map(item => item.deptName)
        .filter((item, index, self) => self.indexOf(item) === index);
    },

    filteredList() {
      // 判断是否已经补过卡
      // 再判断是否已经请假出差

      // 再判断昨天是否加班
      // 再判断用户有没有打卡，打卡有没有迟到早退
      // 再判断是否有哺乳假

      let result = [];

      if (this.deptName) {
        result = JSON.parse(
          JSON.stringify(
            this.pureList.filter(
              item => item.deptName && item.deptName.indexOf(this.deptName) > -1
            )
          )
        );
      } else {
        result = JSON.parse(JSON.stringify(this.pureList));
      }

      if (this.name) {
        result = result.filter(
          item => item.name && item.name.indexOf(this.name) > -1
        );
      }
      console.log("result", result);

      return result.map(item => {
        const obj = { ...item };

        // 员工补卡记录
        const userReplaces = this.replaces.filter(
          leave => leave.userId === item.userId
        );
        for (let i = 0; i < userReplaces.length; i++) {
          const rep = userReplaces[i];
          const dateStr = rep.checkTime.split(" ")[0];
          if (!Array.isArray(obj[dateStr])) {
            continue;
            // 如果没有这一天，说明是节假日或不是工作日的周末，正常情况下不会有补卡记录，除非是测试数据
          }

          if (rep.checkType === "OnDuty") {
            obj[dateStr].push({
              title: "上班已补卡",
              color: "green",
              type: "morning"
            });
          } else if (rep.checkType === "OffDuty") {
            obj[dateStr].push({
              title: "下班已补卡",
              color: "green",
              type: "afternoon"
            });
          }
        }
        //员工请假出差记录
        const userLeaves = this.leaves.filter(
          leave => leave.userId === item.userId
        );
        obj.leaveNum = 0;
        obj.leaveLength = 0;
        for (let i = 0; i < userLeaves.length; i++) {
          const leave = userLeaves[i];
          const startDateObject = moment(leave.startTime);
          const endDateObject = moment(leave.endTime);

          if (
            leave.type !== "on_business_out" &&
            leave.type !== "on_business_in"
          ) {
            obj.leaveNum++;
            obj.leaveLength += leave.duration;
          }

          Object.keys(obj).forEach(key => {
            if (Array.isArray(obj[key])) {
              if (
                startDateObject.isSameOrBefore(key, "days") &&
                endDateObject.isSameOrAfter(key, "days")
              ) {
                const startTimeStr = startDateObject.format("HH:mm:ss");
                const endTimeStr = endDateObject.format("HH:mm:ss");

                if (
                  startDateObject.format("YYYY-MM-DD") ===
                  endDateObject.format("YYYY-MM-DD")
                ) {
                  console.log("就一天假");

                  if (
                    startTimeStr === "09:00:00" &&
                    endTimeStr === "17:30:00"
                  ) {
                    obj[key].unshift({
                      title: getName(leave.type),
                      color: "green",
                      date: key
                    });
                  } else {
                    if (startTimeStr !== "09:00:00") {
                      obj[key].unshift({
                        title: "下午" + getName(leave.type),
                        color: "green",
                        type: "afternoon",
                        date: key
                      });
                    } else {
                      obj[key].unshift({
                        title: "上午" + getName(leave.type),
                        color: "green",
                        type: "morning",
                        date: key
                      });
                    }
                  }
                } else {
                  if (startDateObject.isSame(key, "days")) {
                    // console.log("第一天");
                    if (startTimeStr === "09:00:00") {
                      obj[key].unshift({
                        title: getName(leave.type),
                        color: "green",
                        date: key
                      });
                    } else {
                      obj[key].unshift({
                        title: "下午" + getName(leave.type),
                        color: "green",
                        type: "afternoon",
                        date: key
                      });
                    }
                  } else if (endDateObject.isSame(key, "days")) {
                    if (endTimeStr === "17:30:00") {
                      obj[key].unshift({
                        title: getName(leave.type),
                        color: "green",
                        date: key
                      });
                    } else {
                      obj[key].unshift({
                        title: "上午" + getName(leave.type),
                        color: "green",
                        type: "morning",
                        date: key
                      });
                    }
                  } else {
                    obj[key].unshift({
                      title: getName(leave.type),
                      color: "green",
                      date: key
                    });
                  }
                }
              }
            }
          });
        }

        // 员工打卡记录
        const userList = this.clocks.filter(
          clock => clock.userId === item.userId
        );

        let userClocks = [];
        if (userList[0] && Array.isArray(userList[0].attList)) {
          const attList = userList[0].attList.filter(item => !!item);

          attList.forEach(att => {
            const index = userClocks.findIndex(
              element => element.workDate === att.workDate
            );
            if (index === -1) {
              userClocks.push({
                workDate: att.workDate,
                list: [att]
              });
            } else {
              if (att.checkType === "OnDuty") {
                userClocks[index].list.unshift(att);
              } else {
                userClocks[index].list.push(att);
              }
            }
          });
        }

        for (let i = 0; i < userClocks.length; i++) {
          const clock = userClocks[i];

          if (!Array.isArray(obj[clock.workDate])) {
            continue;
          }

          const goToWorkTimeObject = moment(
            clock.workDate + " 09:00:00",
            "YYYY-MM-DD HH:mm:ss"
          );

          const goToWorkTimeLate5Object = moment(
            clock.workDate + " 09:05:00",
            "YYYY-MM-DD HH:mm:ss"
          );
          const goToWorkTimeLate30Object = moment(
            clock.workDate + " 09:30:00",
            "YYYY-MM-DD HH:mm:ss"
          );
          const goToWorkTimeLate10Object = moment(
            clock.workDate + " 10:00:00",
            "YYYY-MM-DD HH:mm:ss"
          );

          const goToWorkTimeHalfDayObject = moment(
            clock.workDate + " 13:30:00",
            "YYYY-MM-DD HH:mm:ss"
          );

          const goOffWorkTimeObject = moment(
            clock.workDate + " 17:30:00",
            "YYYY-MM-DD HH:mm:ss"
          );
          const goOffWorkTimeEarlyObject = moment(
            clock.workDate + " 17:00:00",
            "YYYY-MM-DD HH:mm:ss"
          );

          const feedObj = obj[clock.workDate].find(
            element =>
              element.title === "哺乳假" && element.date === clock.workDate
          );

          if (feedObj) {
            // 如果有哺乳假，那单独走这套判断逻辑
            console.log("如果有哺乳假，那单独走这套判断逻辑");
            let comeDate = "";
            let goDate = "";
            for (let i = 0; i < clock.list.length; i++) {
              const element = clock.list[i];
              if (element.checkType === "OnDuty") {
                comeDate = element.userCheckTime;
              } else if (element.checkType === "OffDuty") {
                goDate = element.userCheckTime;
              }
            }

            if (comeDate && goDate) {
              // 判断上班时间和9点的分钟差值，如果早于9点是负数
              const comeDiff = moment(comeDate).diff(
                goToWorkTimeObject,
                "minutes"
              );
              const goDiff = moment(goDate).diff(
                goOffWorkTimeObject,
                "minutes"
              );

              if (comeDiff <= 0 && goDiff >= 0) {
                // 上下班都正常

                obj[clock.workDate].push(
                  {
                    title: "上班正常",
                    color: "green",
                    time: comeDate,
                    reason: "",
                    type: "morning",
                    category: "clock"
                  },
                  {
                    title: "下班正常",
                    color: "green",
                    time: goDate,
                    reason: "",
                    type: "afternoon",
                    category: "clock"
                  }
                );
              } else if (comeDiff > 0 && goDiff >= 0) {
                // 下班正常，上班异常
                let newObj;
                if (comeDiff > 60) {
                  newObj = {
                    title: "上班异常",
                    color: "red",
                    time: comeDate,
                    reason: "哺乳假期间",
                    type: "morning",
                    category: "clock"
                  };
                } else {
                  newObj = {
                    title: "上班正常",
                    color: "green",
                    time: comeDate,
                    reason: "哺乳假期间",
                    type: "morning",
                    category: "clock"
                  };
                }

                obj[clock.workDate].push(newObj, {
                  title: "下班正常",
                  color: "green",
                  time: goDate,
                  reason: "",
                  type: "afternoon",
                  category: "clock"
                });
              } else if (comeDiff <= 0 && goDiff < 0) {
                // 下班早退，上班正常
                let newObj;

                if (Math.abs(goDiff) > 60) {
                  newObj = {
                    title: "下班异常",
                    color: "red",
                    time: goDate,
                    reason: "哺乳假期间",
                    type: "afternoon",
                    category: "clock"
                  };
                } else {
                  newObj = {
                    title: "下班正常",
                    color: "green",
                    time: goDate,
                    reason: "哺乳假期间",
                    type: "afternoon",
                    category: "clock"
                  };
                }

                obj[clock.workDate].push(
                  {
                    title: "上班正常",
                    color: "green",
                    time: comeDate,
                    reason: "",
                    type: "morning",
                    category: "clock"
                  },
                  newObj
                );
              } else {
                // 上下班都有问题
                const diff = comeDiff + Math.abs(goDiff);
                if (diff > 60) {
                  obj[clock.workDate].push(
                    {
                      title: "上班异常",
                      color: "red",
                      time: comeDate,
                      reason: "哺乳假期间",
                      type: "morning",
                      category: "clock"
                    },
                    {
                      title: "下班正常",
                      color: "green",
                      time: goDate,
                      reason: "哺乳假期间",
                      type: "afternoon",
                      category: "clock"
                    }
                  );
                } else {
                  obj[clock.workDate].push(
                    {
                      title: "上班正常",
                      color: "green",
                      time: comeDate,
                      reason: "哺乳假期间",
                      type: "morning",
                      category: "clock"
                    },
                    {
                      title: "下班正常",
                      color: "green",
                      time: goDate,
                      reason: "哺乳假期间",
                      type: "afternoon",
                      category: "clock"
                    }
                  );
                }
              }
            } else if (comeDate && !goDate) {
              // 判断上班时间和9点的分钟差值，如果早于9点是负数
              const comeDiff = moment(comeDate).diff(
                goToWorkTimeObject,
                "minutes"
              );
              let newObj;

              if (comeDiff > 60) {
                newObj = {
                  title: "上班异常",
                  color: "red",
                  time: comeDate,
                  reason: "哺乳假期间",
                  type: "morning",
                  category: "clock"
                };
              } else {
                newObj = {
                  title: "上班正常",
                  color: "green",
                  time: comeDate,
                  reason: "哺乳假期间",
                  type: "morning",
                  category: "clock"
                };
              }

              obj[clock.workDate].push(newObj, {
                title: "下班未打卡",
                color: "red",
                type: "afternoon",
                category: "clock"
              });
            } else if (!comeDate && goDate) {
              const goDiff = moment(goDate).diff(
                goOffWorkTimeObject,
                "minutes"
              );
              let newObj;

              if (goDiff < 0 && Math.abs(goDiff) > 60) {
                newObj = {
                  title: "下班异常",
                  color: "red",
                  time: goDate,
                  reason: "哺乳假期间",
                  type: "afternoon",
                  category: "clock"
                };
              } else {
                newObj = {
                  title: "下班正常",
                  color: "green",
                  time: goDate,
                  reason: "哺乳假期间",
                  type: "afternoon",
                  category: "clock"
                };
              }

              obj[clock.workDate].push(
                {
                  title: "上班未打卡",
                  color: "red",
                  type: "morning",
                  category: "clock"
                },
                newObj
              );
            } else {
              console.log("checkType 没匹配上，不可能发生");
            }
          } else {
            for (let i = 0; i < clock.list.length; i++) {
              const element = clock.list[i];

              const userCheckTime = moment(
                element.userCheckTime,
                "YYYY-MM-DD HH:mm:ss"
              );

              if (element.checkType === "OnDuty") {
                if (
                  userCheckTime.isSameOrBefore(goToWorkTimeObject, "minutes")
                ) {
                  obj[clock.workDate].push({
                    title: "上班正常",
                    color: "green",
                    category: "clock",
                    time: element.userCheckTime,
                    type: "morning"
                  });
                } else {
                  let yesterday;

                  const overtime = clock.list.find(
                    element =>
                      element.workDate === clock.workDate &&
                      element.checkType === "OverTime"
                  );

                  if (overtime) {
                    console.log(
                      clock.workDate + "有凌晨加班" + overtime.userCheckTime
                    );

                    yesterday = {
                      ...overtime,
                      isSecond: true
                    };
                  }

                  if (!yesterday) {
                    const yesterdayStr = moment(clock.workDate)
                      .clone()
                      .subtract(1, "days")
                      .format("YYYY-MM-DD");
                    // 可以直接获取到上月最后一天
                    // 周一暂时不考虑，打工人都休息2天了，还要啥合法迟到

                    const yesterdayObj = userClocks.find(
                      c => c.workDate === yesterdayStr
                    );
                    if (yesterdayObj && Array.isArray(yesterdayObj.list)) {
                      const yes = yesterdayObj.list.find(
                        yes => yes.checkType === "OffDuty"
                      );

                      // 钉钉返回的数据正常的 OffDuty，但打卡时间是次日凌晨
                      let isSecond = false;

                      if (yes) {
                        isSecond = moment(yes.userCheckTime).isAfter(
                          yes.workDate,
                          "days"
                        );

                        yesterday = {
                          ...yes,
                          isSecond
                        };
                      }
                    }
                  }

                  let newTime = "";

                  const { userCheckTime: yesterdayUserCheckTime, isSecond } =
                    yesterday || {};

                  if (yesterdayUserCheckTime) {
                    console.log(
                      "yesterdayUserCheckTime",
                      yesterdayUserCheckTime
                    );
                    const yesterdayUserCheckTimeObj = moment(
                      yesterdayUserCheckTime
                    );

                    let yesterdayCheckTimeObj; // 加班判断依据

                    if (isSecond) {
                      yesterdayCheckTimeObj = moment(
                        yesterdayUserCheckTime
                      ).subtract(1, "days");
                    } else {
                      yesterdayCheckTimeObj = moment(yesterdayUserCheckTime);
                    }

                    const yesterday2130 = moment(
                      yesterdayCheckTimeObj.format("YYYY-MM-DD") +
                        " " +
                        "21:00:00",
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    const yesterday2200 = moment(
                      yesterdayCheckTimeObj.format("YYYY-MM-DD") +
                        " " +
                        "22:00:00",
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    const yesterday2300 = moment(
                      yesterdayCheckTimeObj.format("YYYY-MM-DD") +
                        " " +
                        "23:00:00",
                      "YYYY-MM-DD HH:mm:ss"
                    );
                    const yesterday2359 = moment(
                      yesterdayCheckTimeObj.format("YYYY-MM-DD") +
                        " " +
                        "23:59:59",
                      "YYYY-MM-DD HH:mm:ss"
                    );

                    if (
                      yesterdayUserCheckTimeObj.isSameOrAfter(yesterday2130)
                    ) {
                      if (yesterdayUserCheckTimeObj.isBefore(yesterday2200)) {
                        console.log("下班时间在9点到10点之间");
                        newTime = "09:30";
                      } else if (
                        yesterdayUserCheckTimeObj.isBefore(yesterday2300)
                      ) {
                        console.log("下班时间在10点到11点之间");
                        newTime = "10:00";
                      } else if (
                        yesterdayUserCheckTimeObj.isSameOrBefore(yesterday2359)
                      ) {
                        console.log("下班时间在11点到12点之间");
                        newTime = "11:00";
                      } else {
                        console.log(
                          "下班时间在12点之后，不知道钉钉可不可以打卡"
                        );
                        newTime = "13:30";
                      }
                    }
                  }
                  if (newTime) {
                    if (newTime === "09:30") {
                      if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate30Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "上班正常",
                          color: "green",
                          category: "clock",
                          reason: "昨天上班至" + yesterdayUserCheckTime,
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate10Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "迟到",
                          color: "red",
                          category: "clock",
                          reason: "迟到",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                      ) {
                        obj[clock.workDate].push({
                          title: "上午旷工半日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之前打上班卡，算旷工半日",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else {
                        obj[clock.workDate].push({
                          title: "旷工一日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之后打上班卡，算旷工一日",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      }
                    } else if (newTime === "10:00") {
                      const condition = clock.workDate + " 10:00:00";

                      if (userCheckTime.isSameOrBefore(condition, "minutes")) {
                        obj[clock.workDate].push({
                          title: "上班正常",
                          color: "green",
                          category: "clock",
                          reason: "昨天上班至" + yesterdayUserCheckTime,
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                      ) {
                        obj[clock.workDate].push({
                          title: "上午旷工半日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之前打上班卡，算旷工半日",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else {
                        obj[clock.workDate].push({
                          title: "旷工一日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之后打上班卡，算旷工一日",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      }
                    } else if (newTime === "11:00") {
                      const condition = clock.workDate + " 11:00:00";

                      if (userCheckTime.isSameOrBefore(condition, "minutes")) {
                        obj[clock.workDate].push({
                          title: "上班正常",
                          color: "green",
                          category: "clock",
                          reason: "昨天上班至" + yesterdayUserCheckTime,
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                      ) {
                        obj[clock.workDate].push({
                          title: "上午旷工半日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之前打上班卡，算旷工半日",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else {
                        obj[clock.workDate].push({
                          title: "旷工一日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之后打上班卡，算旷工一日",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      }
                    } else {
                      obj[clock.workDate].push({
                        title: "上午不用上班",
                        color: "green",
                        category: "clock",
                        reason: "昨天上班至" + yesterdayUserCheckTime,
                        time: element.userCheckTime,
                        type: "morning"
                      });
                    }
                  } else {
                    if (
                      typeof item.attGroupId === "string" &&
                      item.attGroupId.indexOf("org_product") > -1 &&
                      userCheckTime.isBefore("2023-09-01", "days")
                    ) {
                      // 生产部门的人上班判断逻辑
                      console.log("生产部门的人上班判断逻辑");

                      if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate30Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "上班正常",
                          color: "green",
                          category: "clock",
                          reason: "生产部门9点半之前算正常",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate10Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "迟到",
                          color: "red",
                          category: "clock",
                          reason: "迟到",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                      ) {
                        obj[clock.workDate].push({
                          title: "上午旷工半日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之前打上班卡，算旷工半日",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else {
                        obj[clock.workDate].push({
                          title: "旷工一日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之后打上班卡，算旷工一日",
                          time: element.userCheckTime,

                          type: "morning"
                        });
                      }
                    } else {
                      // 普通员工的上班判断逻辑
                      if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate5Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "迟到",
                          color: "red",
                          category: "clock",
                          reason: "5分钟内迟到",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(
                          goToWorkTimeLate10Object,
                          "minutes"
                        )
                      ) {
                        obj[clock.workDate].push({
                          title: "迟到",
                          color: "red",
                          category: "clock",
                          reason: "迟到",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else if (
                        userCheckTime.isSameOrBefore(goToWorkTimeHalfDayObject)
                      ) {
                        obj[clock.workDate].push({
                          title: "上午旷工半日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之前打上班卡，算旷工半日",
                          time: element.userCheckTime,
                          type: "morning"
                        });
                      } else {
                        obj[clock.workDate].push({
                          title: "旷工一日",
                          color: "red",
                          category: "clock",
                          reason: "13:30之后打上班卡，算旷工一日",
                          time: element.userCheckTime,

                          type: "morning"
                        });
                      }
                    }
                  }
                }
              } else if (element.checkType === "OffDuty") {
                if (userCheckTime.isSameOrAfter(goOffWorkTimeObject)) {
                  obj[clock.workDate].push({
                    title: "下班正常",
                    color: "green",
                    category: "clock",
                    time: element.userCheckTime,
                    type: "afternoon"
                  });
                } else {
                  // 普通员工的下班判断逻辑
                  if (
                    userCheckTime.isBefore(goOffWorkTimeObject) &&
                    userCheckTime.isSameOrAfter(goOffWorkTimeEarlyObject)
                  ) {
                    obj[clock.workDate].push({
                      title: "早退",
                      color: "red",
                      category: "clock",
                      reason: "5点之后5点半之前打卡，算早退",
                      time: element.userCheckTime,
                      type: "afternoon"
                    });
                  } else {
                    obj[clock.workDate].push({
                      title: "下午旷工半日",
                      color: "red",
                      category: "clock",
                      reason: "5点之前下班，过早，算旷工半日",
                      time: element.userCheckTime,
                      type: "afternoon"
                    });
                  }
                }
              } else if (element.checkType === "OverTime") {
                if (
                  userCheckTime.isSameOrBefore(
                    userCheckTime.format("YYYY-MM-DD") + " 06:00:00",
                    "seconds"
                  )
                ) {
                  const key = userCheckTime
                    .clone()
                    .subtract(1, "days")
                    .format("YYYY-MM-DD");

                  console.log("key", key);

                  if (
                    obj[key] &&
                    !obj[key].find(item => item.title === "下班正常")
                  ) {
                    obj[key].push({
                      title: "下班正常",
                      color: "green",
                      category: "clock",
                      time: element.userCheckTime,
                      type: "afternoon"
                    });
                  }
                }
              }
            }
          }
        }

        Object.keys(obj).forEach(key => {
          if (Array.isArray(obj[key])) {
            const length = obj[key].filter(e => e.category === "clock").length;

            if (length === 0) {
              obj[key].push(
                {
                  title: "上班未打卡",
                  color: "red",
                  type: "morning",
                  category: "clock"
                },
                {
                  title: "下班未打卡",
                  color: "red",
                  type: "afternoon",
                  category: "clock"
                }
              );
            } else if (length === 1) {
              if (obj[key][0].type === "morning") {
                obj[key].push({
                  title: "下班未打卡",
                  color: "red",
                  type: "afternoon",
                  category: "clock"
                });
              } else if (obj[key][0].type === "afternoon") {
                obj[key].push({
                  title: "上班未打卡",
                  color: "red",
                  type: "morning",
                  category: "clock"
                });
              }
            }

            // 上午迟到未打卡如果有上午的补卡记录或请求出差记录，直接替换
            let morning = obj[key].filter(item => item.type === "morning");

            const greenMorning = morning.filter(item => item.color === "green");
            if (greenMorning.length > 0) {
              morning = greenMorning;
            }
            // console.log("morning", morning);

            // 下午迟到未打卡如果有下午的补卡记录或请求出差记录，直接替换
            let afternoon = obj[key].filter(item => item.type === "afternoon");

            const greenAfternoon = afternoon.filter(
              item => item.color === "green"
            );
            if (greenAfternoon.length > 0) {
              afternoon = greenAfternoon;
            }

            // 整天假期
            const others = obj[key].filter(
              item => !item.type && item.color === "green"
            );
            const noFeed = others.filter(item => item.title !== "哺乳假");
            if (noFeed.length > 0) {
              // 有了除哺乳假之外的其他整天假期，那就不用显示其他的上班/下班迟到早退未打卡了，如果有半天的假期，也会被隐藏，这个无所谓，理论上假期不允许重叠。
              obj[key] = others;
            } else {
              obj[key] = [...morning, ...afternoon, ...others];
            }
          }
        });

        let lateNum = 0;
        let lateLength = 0;
        let earlyNum = 0;
        let earlyLength = 0;
        let forgetNum = 0;

        const today = moment();

        Object.keys(obj).forEach(key => {
          if (Array.isArray(obj[key])) {
            const lateObj = obj[key].find(
              item => item.title.indexOf("迟到") > -1
            );
            if (lateObj) {
              const lateObjMoment = moment(lateObj.time);
              lateNum++;

              if (
                typeof obj.attGroupId === "string" &&
                obj.attGroupId.indexOf("org_product") > -1 &&
                lateObjMoment.isBefore("2023-09-01", "days")
              ) {
                lateLength += lateObjMoment.diff(
                  lateObjMoment.format("YYYY-MM-DD") + " 09:31:00",
                  "seconds"
                );
              } else {
                lateLength += lateObjMoment.diff(
                  lateObjMoment.format("YYYY-MM-DD") + " 09:01:00",
                  "seconds"
                );
              }
            }
            const earlyObj = obj[key].find(
              item => item.title.indexOf("早退") > -1
            );
            if (earlyObj) {
              const earlyObjMoment = moment(earlyObj.time);
              earlyNum++;
              earlyLength += moment(
                earlyObjMoment.format("YYYY-MM-DD") + " 17:30:00"
              ).diff(earlyObj.time, "seconds");
            }

            if (today.isBefore(key, "date")) {
              console.log(key + "未到");
            } else if (today.isSame(key, "date")) {
              console.log("key" + "是今天");
              if (
                today.isAfter(
                  today.format("YYYY-MM-DD") + " 17:34:00",
                  "seconds"
                )
              ) {
                console.log("今天超过了下午5点34");
                const l = obj[key].filter(
                  item => item.title.indexOf("未打卡") > -1
                ).length;
                if (l > -1) {
                  forgetNum += l;
                }
              } else if (
                today.isAfter(
                  today.format("YYYY-MM-DD") + " 09:10:00",
                  "seconds"
                )
              ) {
                console.log("今天超过了上午9点10");

                const l = obj[key].filter(
                  item => item.title.indexOf("上班未打卡") > -1
                ).length;
                if (l > -1) {
                  forgetNum += l;
                }
              }
            } else {
              const l = obj[key].filter(
                item => item.title.indexOf("未打卡") > -1
              ).length;
              if (l > -1) {
                forgetNum += l;
              }
            }
          }
        });

        obj.lateNum = lateNum;

        obj.lateLength = Math.round(lateLength / 60);
        obj.earlyNum = earlyNum;
        obj.earlyLength = Math.round(earlyLength / 60);
        obj.forgetNum = forgetNum;

        console.log("obj", obj);
        return obj;
      });
    },

    abnormalNums() {
      let lateNum = 0;
      let earlyNum = 0;
      let forgetNum = 0;

      const dept = {};
      const depts = [];

      for (let i = 0; i < this.filteredList.length; i++) {
        const item = this.filteredList[i];

        if (dept[item.deptName]) {
          dept[item.deptName].userSum++;

          if (item.lateNum > 0) {
            dept[item.deptName].lateNum++;
          }
          if (item.earlyNum > 0) {
            dept[item.deptName].earlyNum++;
          }
          if (item.forgetNum > 0) {
            dept[item.deptName].forgetNum++;
          }
          if (item.leaveNum > 0) {
            dept[item.deptName].leaveNum++;
          }
        } else {
          dept[item.deptName] = {
            userSum: 1,
            lateNum: item.lateNum > 0 ? 1 : 0,
            earlyNum: item.earlyNum > 0 ? 1 : 0,
            forgetNum: item.forgetNum > 0 ? 1 : 0,
            leaveNum: item.leaveNum > 0 ? 1 : 0
          };
        }

        if (item.lateNum > 0) {
          lateNum++;
        }
        if (item.earlyNum > 0) {
          earlyNum++;
        }
        if (item.forgetNum > 0) {
          forgetNum++;
        }
      }

      Object.keys(dept).forEach(key => {
        depts.push({
          name: key,

          userSum: dept[key].userSum,
          lateNum: dept[key].lateNum,
          earlyNum: dept[key].earlyNum,
          forgetNum: dept[key].forgetNum,
          leaveNum: dept[key].leaveNum
        });
      });

      return {
        lateNum,
        earlyNum,
        forgetNum,

        depts
      };
    },

    depts() {
      if (Array.isArray(this.abnormalNums.depts)) {
        return this.abnormalNums.depts.map(item => {
          return {
            name: item.name,
            value: Math.round(item[this.ratioType] / item.userSum)
          };
        });
      } else {
        return [];
      }
    }
  },

  watch: {
    currentMonth() {
      this.init();
    }
  },

  mounted() {
    this.getHoliday().then(() => {
      this.init();
    });
  },

  methods: {
    ...mapActions("holiday", ["getHoliday"]),

    isHoliday(date) {
      let status = "";

      loop: for (let i = 0; i < this.holiday.length; i++) {
        const item = this.holiday[i];
        if (Array.isArray(item.data)) {
          for (let j = 0; j < item.data.length; j++) {
            const element = item.data[j];

            if (
              date.isSameOrAfter(element.startHoliday, "day") &&
              date.isSameOrBefore(element.endHoliday, "day")
            ) {
              status = "holiday";
              break loop;
            }

            for (let x = 0; x < element.workday.length; x++) {
              if (date.isSame(element.workday[x], "day")) {
                status = "workday";
                break loop;
              }
            }
          }
        }
      }

      return status;
    },

    init() {
      const year = this.currentMonth.year();
      const month = this.currentMonth.month() + 1;

      let days = 0;

      if ([1, 3, 5, 7, 8, 10, 12].indexOf(month) > -1) {
        days = 31;
      } else if ([4, 6, 9, 11].indexOf(month) > -1) {
        days = 30;
      } else if (month === 2) {
        if (isLeapYear(year)) {
          days = 29;
        } else {
          days = 28;
        }
      }

      const columns = [];

      for (let i = 1; i <= days; i++) {
        const date = new Date(year, month - 1, i);
        const week = date.getDay();
        const dateObj = moment(date);
        const status = this.isHoliday(dateObj);

        let needCalculate = true;
        if (status === "holiday") {
          needCalculate = false;
        } else if (status === "workday") {
          needCalculate = true;
        } else {
          if (week === 0 || week === 6) {
            needCalculate = false;
          } else {
            needCalculate = true;
          }
        }

        columns.push({
          key: dateObj.format("YYYY-MM-DD"),
          title: i + "号",
          needCalculate
        });
      }
      this.columns = columns;

      this.getData();
      this.getLeave();
      this.getReplace();
    },

    getData() {
      const hide = this.$message.loading("加载中...");
      fetchAnalysis({
        workDate: this.currentMonth.format("YYYY-MM")
      })
        .then(res => {
          if (Array.isArray(res)) {
            this.clocks = Object.freeze(res);

            const obj = {};
            this.columns.forEach(item => {
              if (item.needCalculate) {
                obj[item.key] = [];
              }
            });

            // .filter(item => item.name === "林心洁")
            this.pureList = res.map(item => {
              return {
                userId: item.userId,
                name: item.name,
                deptName: item.deptName,
                attGroupId: item.attGroupId,

                ...obj
              };
            });
          }
        })
        .finally(() => {
          hide();
        });
    },

    getLeave() {
      const startTime =
        this.currentMonth.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
      const endTime =
        this.currentMonth.endOf("month").format("YYYY-MM-DD") + " 23:59:59";

      fetchLeave({
        startTime,
        endTime,
        statusList: ["reviewing", "approved"]
      }).then(res => {
        if (Array.isArray(res)) {
          this.leaves = Object.freeze(
            res.filter(item => item.optType !== "cancel")
          );

          this.holidayUserLength = this.leaves
            .filter(
              item =>
                item.type !== "on_business_out" &&
                item.type !== "on_business_in"
            )
            .map(item => item.userId)
            .filter((item, index, self) => self.indexOf(item) === index).length;
          this.businessUserLength = this.leaves
            .filter(item => item.type === "on_business_out")
            .map(item => item.userId)
            .filter((item, index, self) => self.indexOf(item) === index).length;
          this.outUserLength = this.leaves
            .filter(item => item.type === "on_business_in")
            .map(item => item.userId)
            .filter((item, index, self) => self.indexOf(item) === index).length;
        }
      });
    },

    getReplace() {
      const startTime =
        this.currentMonth.startOf("month").format("YYYY-MM-DD") + " 00:00:00";
      const endTime =
        this.currentMonth.endOf("month").format("YYYY-MM-DD") + " 23:59:59";

      fetchCheckApply({
        startTime,
        endTime,
        applyStatusList: ["reviewing", "approved"]
      }).then(res => {
        if (Array.isArray(res)) {
          this.replaces = Object.freeze(res);
        }
      });
    },

    downloadDeptErrorRatio() {
      domtoimage
        .toBlob(document.getElementById("dept-error-ratio"))
        .then(function(blob) {
          saveAs(blob, "各部门异常比例.png");
        });
    },

    downloadDeptError() {
      domtoimage
        .toBlob(document.getElementById("dept-error"))
        .then(function(blob) {
          saveAs(blob, "各部门异常情况.png");
        });
    },

    downloadTable() {
      downloadByHtml(document.getElementsByTagName("table")[0], "异常排行榜");
    }
  }
};
</script>


<style lang="less" scoped>
.card {
  border-radius: 4px;
  padding: 8px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .title {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.print {
  background-color: #fff;
}
</style>